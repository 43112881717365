import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParesPipe } from './pares.pipe';
import { FixPagePipe } from './fixPage.pipe';
import { DomSanitizerPipe } from './dom-sanitizer.pipe';
import { FixUrlPipe } from './fix-url.pipe';
import { FixStylePipe } from './fix-style.pipe';
import { FilterImagePipe } from './filter-image.pipe';
import { TrustVideoPipe } from './trust-video.pipe';
import { CurrencyPipe } from './currency.pipe';
import { RouterPipe } from './router.pipe';
import { QueryPipe } from './query.pipe';
import { TypeProductPipe } from './type-product.pipe';
import { CalcAdditionalSubtotalPipe } from './calc-additional-subtotal.pipe';
import { CalcSwipesPipe } from './calc-swipes.pipe';
import { NewSwipeOptsPipe } from './new-swipe-opts.pipe';
import { CalcProductTaxPipe } from './calc-product-tax.pipe';
import { CalcSubscriptionPricePipe } from './calc-subscription-price.pipe';
import { FindModuleNamePipe } from './find-module-name.pipe';

const pipes = [
  ParesPipe,
  FixPagePipe,
  DomSanitizerPipe,
  FixUrlPipe,
  FixStylePipe,
  FilterImagePipe,
  TrustVideoPipe,
  CurrencyPipe,
  RouterPipe,
  QueryPipe,
  TypeProductPipe,
  CalcAdditionalSubtotalPipe,
  CalcSwipesPipe,
  NewSwipeOptsPipe,
  CalcProductTaxPipe,
  CalcSubscriptionPricePipe,
  FindModuleNamePipe,
];

@NgModule({
  declarations: pipes,
  imports: [CommonModule],
  exports: pipes,
})
export class PipeModule {}
