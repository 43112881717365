import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findModuleName',
})
export class FindModuleNamePipe implements PipeTransform {
  constructor() {}

  transform(
    value: { name: string; newName: string }[],
    moduleName: string
  ): string {
    const module = value.find((item) => item.name === moduleName);
    console.log('module :>> ', module);
    return module?.newName || moduleName;
  }
}
