import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NavController } from '@ionic/angular';
import { CartServiceService } from 'src/app/services/cart-service.service';
import { UiServices } from 'src/app/services/ui-services';
import { MultioComponent } from '../multio/multio.component';
import { MenuService } from 'src/app/services/menu.service';
import { environment } from 'src/environments/environment';
import { ChangeBranchComponent } from '../change-branch/change-branch.component';

@Component({
  selector: 'app-menu-ssr',
  templateUrl: './menu-ssr.component.html',
  styleUrls: ['./menu-ssr.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSsrComponent implements OnInit {
  @Input() colors = undefined;
  @Input() loginSv = undefined;
  @Input() balanceSv = undefined;
  @Input() wallet: any = undefined;
  @Input() headerOpts: any = undefined;
  @Input() staticOpts = [];
  @Output() comprobeLogin = new EventEmitter();
  public hiddeMenu = false;
  public domainName = environment.domainName;

  constructor(
    private cartSv: CartServiceService,
    private navCtrl: NavController,
    private uiSv: UiServices,
    public menuSv: MenuService
  ) {}

  ngOnInit() {}

  closeMenu(ev?) {
    if (ev) {
      if (ev.target.tagName !== 'ASIDE' && ev.target.tagName !== 'ION-BUTTON') {
        return false;
      }
    }

    this.hiddeMenu = !this.hiddeMenu;
    setTimeout(() => {
      this.hiddeMenu = !this.hiddeMenu;
      this.menuSv.toogleMenu.next(false);
    }, 400);
  }

  async emitUrl(ev, evn) {
    evn.stopPropagation();
    const url = ev?.url;
    const currency = ev?.changeCurrency;
    const branch = ev?.branch;
    const component = currency
      ? MultioComponent
      : branch
      ? ChangeBranchComponent
      : false;
    if (component) {
      const { data } = await this.uiSv.showPopover(
        component,
        {},
        branch ? 'branch-pop' : ''
      );
      if (data) {
        this.closeMenu();
      }
      return;
    }
    if (url === 'logout') {
      await this.loginSv.logout();
      this.cartSv.logined = false;
      this.comprobeLogin.emit();
    } else if (url) {
      await this.navCtrl.navigateForward(url);
    }
    this.closeMenu();
  }

  async goTo(opts, categoryId?, childId?, external?, ev?) {
    ev.stopPropagation();
    this.closeMenu();

    const queryParams: any = {};
    this.cartSv.clearFilter();
    childId && (queryParams.chCategory = childId);
    if (opts?.type === 'headersLink') {
      if (categoryId) {
        const btn = opts.headersLink.child.find((x) => x.id === categoryId);
        await this.navCtrl.navigateForward(btn.redirectUrl);
      } else {
        const url = opts.headersLink?.redirectUrl;
        if (!url) return false;
        await this.navCtrl.navigateForward(url);
        return true;
      }
    } else if (opts.type === 'categories') {
      this.cartSv.marketplaceFilers.category = categoryId || opts.relation;
      this.doSearch();
    } else if (typeof opts === 'string') {
      this.navCtrl.navigateForward(opts);
    } else if (opts.type === 'catalogues') {
      const id = opts.relation;
      categoryId && (queryParams.category = categoryId);
      await this.navCtrl.navigateForward(`catalogue/${id}`, { queryParams });
    } else if (opts.type === 'services') {
      if (categoryId) {
        await this.navCtrl.navigateForward(`catalogue/${categoryId}`, {
          queryParams,
        });
      } else {
        this.cartSv.marketplaceFilers.services = 1;
        this.doSearch();
        return true;
      }
    } else if (opts.type === 'subscription') {
      this.cartSv.marketplaceFilers.subscription = 1;
      this.doSearch();
      return true;
    }

    if (external) {
      const type = !!opts.includes('https');
      if (type) {
        window.open(opts, '_blank');
      } else {
        await this.navCtrl.navigateForward(opts);
      }
      return true;
    }
  }

  async doSearch() {
    try {
      this.cartSv.marketplaceFilers.search = '';
      this.cartSv.marketplaceFilers.pag = 1;
      this.navCtrl.navigateForward('marketplace', {
        queryParams: this.cartSv.marketplaceFilers,
      });
    } catch (err) {
      console.error(err);
    }
  }

  trackByItems(index, item) {
    if (item) {
      return item?.id ? item.id : item?.name;
    }
    return '';
  }
}
