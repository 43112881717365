import { Component, ElementRef, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ParamServicesService } from 'src/app/services/param-services.service';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent implements OnInit {
  public img: any = undefined;
  public seo = undefined;
  public imgType = 'imageFile';

  constructor(
    private paramSv: ParamServicesService,
    private modalController: ModalController,
    private elementRef: ElementRef
  ) {}

  async ngOnInit() {
    try {
      const data = (await this.paramSv.getImages('5', '0')) || [];
      const colors = await this.paramSv.getOnlyParam('colors');
      const seo = this.paramSv.seo;

      const backgroundSplash: any[] = colors ? JSON.parse(colors) : undefined;

      if (backgroundSplash) {
        const splashColor = backgroundSplash.find(
          (item) => item.name === '--splash'
        );
        if (splashColor) {
          this.elementRef.nativeElement.style.setProperty(
            '--splash',
            splashColor.color
          );
          this.elementRef.nativeElement.style.setProperty(
            '--darkSplashr',
            splashColor.darkColor
          );
        }

        const statusColor = backgroundSplash.find(
          (item) => item.name === '--status'
        );
        if (statusColor) {
          this.elementRef.nativeElement.style.setProperty(
            '--status',
            statusColor.color
          );
          this.elementRef.nativeElement.style.setProperty(
            '--darkStatus',
            statusColor.darkColor
          );
        }
      }

      data.forEach((item: any) => (this.img = item.images[0]));

      const paramSv: Subscription = this.paramSv.emitImagesObs.subscribe(
        async (data) => {
          if (data === 'finish') {
            paramSv.unsubscribe();
            if (!seo?.status || seo?.status === '1') {
              await this.modalController.dismiss();
            } else {
              this.img = undefined;
              this.seo = seo;
              const match = window.matchMedia('(min-width: 768px)').matches;
              if (!match) this.imgType = 'imageFileMovil';
              window
                .matchMedia('(min-width: 768px)')
                .addEventListener('change', (ev) => {
                  if (ev.matches) {
                    this.imgType = 'imageFile';
                  } else {
                    this.imgType = 'imageFileMovil';
                  }
                });
            }
          }
        }
      );

      await this.paramSv.getParams();
      await this.paramSv.getImages();
    } catch (err) {
      console.error(err);
    }
  }
}
