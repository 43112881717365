import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'currency',
})
export class CurrencyPipe implements PipeTransform {
  transform(price: number, currency: any): number {
    if (!currency && price) {
      return Math.trunc(price * 100) / 100;
    } else if (!price || isNaN(price)) {
      return 0;
    }

    if (Number(currency?.type) === 1) {
      // price = Number((price * currency.change).toFixed(2));
      price = Math.trunc(price * currency.change * 100) / 100;
    } else if (Number(currency?.type) === 0) {
      // price = Number((price / currency.change).toFixed(2));
      price = Math.trunc((price / currency.change) * 100) / 100;
    }
    return price;
  }
}
